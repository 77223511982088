<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s truncate-2-lines font-header">
        Ubah "{{ cage.name }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container />
    <div id="container-modal-add" />
    <modal-out-confirm />
    <modal-save-confirm />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      cage: {},
      breadcrumb: [
        {
          link: '/admin/jenis-kandang',
          title: 'Jenis Kandang'
        },
        {
          link: null,
          title: 'Ubah Data'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      cageCategoryDetail: 'cageCategory/cageCategoryDetail'
    })
  },
  async mounted() {
    await this.getCageCategoryDetail(this.$route.params.id)
    this.cage = this.cageCategoryDetail.data
  },
  methods: {
    ...mapActions({
      getCageCategoryDetail: 'cageCategory/getCageCategoryDetail'
    })
  }
}
</script>
